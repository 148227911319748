// @file Global input dialog store
import { defineStore } from 'pinia'
import { ref } from 'vue'

type InputDialogAction = ({ inputValue }: { inputValue: string }) => void

interface HeaderActionButton {
  iconName: string
  iconAlt?: string
  actions: Function[]
  ariaLabel?: string
  title?: string
}

interface InputDialogPayload {
  iconSrc?: string | null
  iconAlt?: string | null
  title: string
  subtitle?: string
  inputPlaceholder?: string
  inputRequired?: boolean
  inputLabel?: string
  inputValue?: string
  inputType?: string
  inputMaxLength?: number | null
  inputAriaLabel?: string
  submitButtonText?: string
  cancelButtonText?: string
  submitActions: InputDialogAction[]
  validationActions?: InputDialogAction[]
  shouldFadeIn?: boolean
  isValid?: boolean
  validationMessage?: string
  headerTopEndContent?: HeaderActionButton | null
  submitButtonDisabled?: boolean
}

const defaultInputDialogData: InputDialogPayload = {
  iconSrc: null,
  iconAlt: null,
  title: '',
  subtitle: '',
  inputPlaceholder: '',
  inputRequired: true,
  inputLabel: '',
  inputValue: '',
  inputType: 'text',
  inputMaxLength: null,
  inputAriaLabel: '',
  submitButtonText: '',
  cancelButtonText: '',
  submitActions: [],
  validationActions: [],
  shouldFadeIn: true,
  isValid: true,
  validationMessage: '',
  headerTopEndContent: null,
  submitButtonDisabled: false,
}

export const useGlobalInputDialogStore = defineStore('globalInputDialog', () => {
  const isOpen = ref<boolean>(false)
  const inputDialogData = ref<InputDialogPayload>(defaultInputDialogData)

  const openInputDialog = (payload: InputDialogPayload): void => {
    inputDialogData.value = Object.assign({}, inputDialogData.value, payload)
    isOpen.value = true
    executeActions(inputDialogData.value.validationActions)
  }

  const resetInputDialog = (): void => {
    isOpen.value = false
    inputDialogData.value = Object.assign({}, inputDialogData.value, defaultInputDialogData)
  }

  const executeActions = (actions?: InputDialogAction[]): void => {
    if (actions === undefined) return

    actions.forEach((action): void => {
      if (typeof action === 'function') {
        action({ inputValue: inputDialogData.value.inputValue ?? '' })
      }
    })
  }

  const submitInputDialog = (): void => {
    if (inputDialogData.value.submitButtonDisabled) return
    executeActions(inputDialogData.value.submitActions)
    resetInputDialog()
  }

  const closeInputDialog = (): void => {
    resetInputDialog()
  }

  const setSubmitButtonDisable = (disable: boolean): void => {
    if (inputDialogData.value.submitButtonDisabled !== disable) inputDialogData.value.submitButtonDisabled = disable
  }

  const setInputIsValid = (isValid: boolean): void => {
    inputDialogData.value.isValid = isValid
  }

  const setValidationMessage = (message: string): void => {
    inputDialogData.value.validationMessage = message
  }

  const updateInputValue = (value: string): void => {
    inputDialogData.value.inputValue = value
    executeActions(inputDialogData.value.validationActions)
  }

  return {
    // State
    isOpen,
    inputDialogData,

    // Actions
    openInputDialog,
    submitInputDialog,
    closeInputDialog,
    setSubmitButtonDisable,
    setInputIsValid,
    setValidationMessage,
    updateInputValue,
  }
})
